
      @import "variables/_colors.scss";
      @import "variables/_fontFamily.scss";
    
.main {
    position: relative;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    padding: 80px 0;

    > div {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding-right: 16px;

        h1, h2 {
            width: 100%;
            color: $white;
            text-transform: unset;
            text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.20);
        }
    }
}