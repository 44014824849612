$white: #FFFFFF;
$light-gray-1: #D9D9D9;
$light-gray-2: #B2B2B2;
$dark-gray: #868686;
$outer-space: #444444;
$raisin-black: #181818;
$ultramarine-blue: #1F5DA1;
$ultramarine-blue-17-opacity: #1f5ea12c;
$ultramarine-blue-75-opacity: #1f5ea1be;
$light-sky-blue: #74B3E2;
$light-sky-blue-30-opacity: #74b3e24d;
$golden-yellow: #FFDD00;
$light-goldenrod-yellow: #FFEF66;
$golden-yellow-17-opacity: #ffdd002b;
$jet: #333333;
$black: #000000;
$gray: #808080;
$silver-sand: #A3A3A3;
$light-gray-3: #C2C2C2;
$platinum: #E6E6E6;
$cultured: #FAFAFA;
$very-light-blue: #A6D7F5;
$lemon-meringue: #FFF7A9;
$eerie-black: #202020;
$eerie-black-50-opacity: #20202079;
$amaranth: #E22E2E;
$salmon: #fa7e7e;


$granite-gray: #686D71;
$whisper: #F1F1F1;
$light-gray: #D3D3D3;
$white-40-opacity: #FFFFFF66;
$black-30-opacity: #0000004c;
$sky-blue: #80C9FF;
$charcoal: #313941;
$lust-red: #E40521;
$sonic-silver: #7C7C7C;
$silver-chalice: #AFAFAF;
$eerie-black-25-opacity: #20202040;
$black-25-opacity: #00000040;
$black-60-opacity: #00000099;
$granite-gray-12-opacity: #686d711f;
$onyx: #343434;
$tufts-blue: #4F9EDD;
$tufts-blue-12-opacity: #4f9edd1f;
$sonic-silver-60-opacity: #7c7c7c99;
$eerie-black-40-opacity: #20202066;
$granite-gray-10-opacity: #686D7133;
$tufts-blue-20-opacity: #4f9edd33;