
      @import "variables/_colors.scss";
      @import "variables/_fontFamily.scss";
    
.main {
    position: relative;
    max-width: 1920px;
    margin: 0 auto;
    width: 100%;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    padding: 80px 0;

    > div:first-child {
        width: 59% !important;
        margin: 0;
    }

    > div {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 0 48px;
        width: 1140px;
        margin: 0 auto;

        h1, h2 {
            color: $white;
            text-transform: unset;
            text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.20);
        }
    }
}