@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.cdnfonts.com/css/muller-next-expanded-trial');

@font-face {
    font-family: "AZUL AGRO";
    font-display: swap;
    src: url("../../../public/fonts/AZUL_AGRO.ttf");
}

@font-face {
    font-family: "CMS";
    font-display: swap;
    src: url("../../../public/fonts/CMS.ttf");
}
